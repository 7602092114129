@import "styles/theme.scss";
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.image {
  max-width: 100%;
  display: block;
}
