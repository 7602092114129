@import "styles/theme.scss";
.content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 12px;
}

.card {
  &Dark {
    background-color: rgba($grey-color, 0.5);
  }
}
