$font-family: 'Montserrat', sans-serif;

$text-color: #003251;

$invisible-color: rgba(0, 0, 0, 0);

$blue-extra-light-color: #1482fa1a;
$blue-light-color: #f1f7fc;
$accent-color: #00d1ff;

$grey-extra-light-color: #d9d9d9;
$grey-light-color: #f4f4f4;

$grey-color: #818ba7;
$grey-dark-color: #c7d4e0;

$default-right-color: #1482fa;
$wrong-color: #ff1f26;
$success-color: #3f9142;

$grey-dark-color: #c7d4e0;

$font-weight-light: 300;
$font-weight-medium: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

$scroll-width: 17px;

$table-padding: 12px;

$mobile-s: 320px;
$mobile-m: 375px;
$mobile-l: 425px;
$tablet: 768px;
$laptop: 1024px;
$laptop-height: 1366px;
$laptop-l: 1440px;

$font-size-xxs: 8px;
$font-size-xs: 10px;
$font-size-s: 12px;
$font-size-m: 14px;
$font-size-l: 16px;
$font-size-xl: 20px;
$font-size-xxl: 24px;

$border-radius-s: 6px;

$box-shadow-main: 0 5px 10px rgba(138, 170, 219, 0.5),
  0 12px 32px rgba(138, 170, 219, 0.25);

@mixin input-focus {
  font-family: inherit;
  resize: none;
  outline: inherit;
}

@mixin input-error {
  box-shadow: inset 0 0 0 2px $wrong-color;
}

@mixin label-title {
  display: block;

  margin-bottom: 6px;

  font-weight: $font-weight-semi-bold;
}

@mixin error-message {
  padding-top: 5px;

  color: $wrong-color;

  font-size: $font-size-xs;
}

@mixin input-valid {
  box-shadow: inset 0 0 0 2px $accent-color;
}
