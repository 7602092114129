@import "styles/theme.scss";
.container {
  display: flex;

  flex-direction: column;

  height: 100%;
}

.separator {
  margin: 20px 0;

  background-color: $grey-extra-light-color;

  border-width: 0;
  height: 1px;
}

.title {
  font-size: $font-size-xl;

  padding-bottom: 20px;
}

.image {
  padding-bottom: 20px;
}

.card {
  display: grid;

  grid-template-columns: 30px 1fr;

  align-items: center;

  grid-column-gap: 10px;
  grid-row-gap: 20px;
}

.list {
  &Detailing {
    display: grid;

    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);

    gap: 20px;
  }
}

.item {
  display: grid;

  grid-template-columns: 30px 1fr;

  grid-column-gap: 12px;

  align-items: center;

  & + & {
    margin-top: 28px;
  }

  &Detailing {
    background-color: $blue-light-color;

    &_Company {
      grid-area: 2 / 1 / 3 / 3;
    }
  }

  &Value {
    grid-area: 2 / 2 / 3 / 3;
  }

  &Title {
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
  }

  &Text {
    color: $accent-color;

    font-size: $font-size-xxl;
    font-weight: $font-weight-bold;

    & + & {
      margin-left: 36px;
    }

    span {
      color: $text-color;

      font-size: $font-size-l;
      font-weight: $font-weight-medium;
    }
  }

  &Image {
    display: flex;

    width: 30px;
    height: 30px;

    flex-shrink: 0;
  }

  &Value {
    white-space: nowrap;
  }
}

.access {
  display: flex;
  align-items: center;

  margin: auto;

  &Image {
    width: 18px;
    height: 18px;

    margin-right: 8px;
  }

  &Text {
    color: white;

    text-decoration: underline;

    cursor: pointer;
  }
}
